import { FC, useState } from 'react';
import { Table, Space, message } from 'antd';
import { Button, Modal, Input, AttachedSelect as Select } from 'iglooform';
import { useIntl, history } from 'umi';
import { useRequest } from '@umijs/hooks';
import { useRecoilValue } from 'recoil';
import { currentPlatformState } from '@/store/global';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Import from './components/import';
import {
  getPermissions,
  getRoles,
  updateRole,
  deleteRole,
  createRole,
} from './service';
import { s2ab, openDownloadDialog } from '@/utils/download';
import xlsx from 'xlsx';

import styles from './index.less';

const Role: FC = () => {
  const [permissions, setPermissions] = useState<Auth.Permission[]>([]);
  const [roles, setRoles] = useState<Auth.Role[]>([]);
  const { formatMessage } = useIntl();
  const currentPlatform = useRecoilValue(currentPlatformState) as string;
  const [showModal, setShowModal] = useState(false);
  const [showUpload, setShowUplaod] = useState(false);
  const [editRole, setEditRole] = useState<Auth.EditRole>({} as Auth.EditRole);

  useRequest(getPermissions, {
    defaultParams: [currentPlatform],
    onSuccess: ({ permissions }) => {
      setPermissions(permissions);
    },
    cacheKey: `${currentPlatform}-permissions`,
  });

  const { run: refresh, loading } = useRequest(getRoles, {
    defaultParams: [currentPlatform],
    onSuccess: ({ roles }) => {
      setRoles(roles);
    },
    cacheKey: `${currentPlatform}-roles`,
  });

  const { run: create, loading: createLoading } = useRequest(createRole, {
    manual: true,
    onSuccess: () => {
      refresh(currentPlatform);
      setShowModal(false);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  const { run: update, loading: updateLoading } = useRequest(updateRole, {
    manual: true,
    onSuccess: () => {
      refresh(currentPlatform);
      setShowModal(false);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  const { run: del } = useRequest(deleteRole, {
    manual: true,
    onSuccess: () => {
      refresh(currentPlatform);
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  if (!currentPlatform) {
    history.push('/platforms');
    return null;
  }

  const openCreateModal = () => {
    setEditRole({} as Auth.EditRole);
    setShowModal(true);
  };

  const handleSave = () => {
    if (editRole.id !== undefined) {
      update(editRole.id, editRole);
      return;
    }

    create(currentPlatform, editRole);
  };

  const handleDelete = ({ id }: any) => {
    Modal.confirm({
      title: 'Please Confirm',
      content: 'Deleting role will affect all related accounts.',
      onOk: () => confirmDelete(id),
    });
  };

  const confirmDelete = (id: any) => {
    del(id);
  };

  const handleExport = () => {
    const workbook = xlsx.utils.book_new();
    const sheet = xlsx.utils.aoa_to_sheet([
      ['key', 'permissionKeys'],
      ...roles.map(({ key, permissions }) => {
        return [key, permissions.map(({ key }) => key).join(',')];
      }),
    ]);
    xlsx.utils.book_append_sheet(workbook, sheet);

    const wopts = {
      bookType: 'xlsx', // 要生成的文件类型
      bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
      type: 'binary',
    } as any;
    const wbout = xlsx.write(workbook, wopts);
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    openDownloadDialog(blob, `${currentPlatform}-roles.xlsx`);
  };

  return (
    <div className={styles.container}>
      <Space className={styles.buttons} size={16}>
        <Button type="default" onClick={openCreateModal}>
          {formatMessage({
            id: 'New Role',
            defaultMessage: 'New Role',
          })}
        </Button>
        <Button type="default" onClick={() => setShowUplaod(true)}>
          {formatMessage({
            id: 'Import Role',
            defaultMessage: 'Import Role',
          })}
        </Button>
        <Button type="default" onClick={handleExport}>
          {formatMessage({
            id: 'Export Role',
            defaultMessage: 'Export Role',
          })}
        </Button>
      </Space>
      <Table
        className={styles.table}
        loading={loading}
        dataSource={roles}
        columns={[
          {
            title: formatMessage({
              id: 'Key/Name',
              defaultMessage: 'Key/Name',
            }),
            dataIndex: 'key',filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
              return (
                <Space direction="vertical" size={16} style={{ padding: 24 }}>
                  <Input
                    onChange={(e) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                  />
                  <Space size={16}>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        confirm();
                      }}
                      type="text"
                      size="small"
                    >
                      Reset
                    </Button>
                    <Button onClick={() => confirm()} type="text" size="small">
                      Confirm
                    </Button>
                  </Space>
                </Space>
              );
            },
            onFilter: (value, record) =>
              Boolean(record.key?.includes(value as string)),
          },
          {
            title: formatMessage({
              id: 'Permissions',
              defaultMessage: 'Permissions',
            }),
            dataIndex: 'permissions',
            render: (permissions) => {
              return permissions?.map(({ key }: any) => key).join(', ');
            },
          },
          {
            title: formatMessage({
              id: 'Operation',
              defaultMessage: 'Operation',
            }),
            width: '20%',
            render: (_, record) =>
              record?.key === 'admin' ? (
                '-'
              ) : (
                <>
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => {
                      setEditRole({
                        ...record,
                        permissions: record?.permissions?.map(
                          ({ id }) => id as number,
                        ),
                      });
                      setShowModal(true);
                    }}
                    type="link"
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => handleDelete(record)}
                    style={{ marginLeft: 16 }}
                    type="link"
                  />
                </>
              ),
          },
        ]}
        rowKey="key"
      />
      <Modal
        visible={showUpload}
        width="80vw"
        footer={null}
        title={formatMessage({
          id: 'Import Role',
          defaultMessage: 'Import Role',
        })}
        destroyOnClose
        onCancel={() => {
          refresh(currentPlatform);
          setShowUplaod(false);
        }}
        maskClosable={false}
        forceRender={false}
        limitHeight={false}
      >
        <Import permissions={permissions} />
      </Modal>
      <Modal
        className={styles.modal}
        width={600}
        forceRender={false}
        maskClosable={false}
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        title={formatMessage({
          id: 'New Role',
          defaultMessage: 'New Role',
        })}
        destroyOnClose
        limitHeight={false}
      >
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({ id: 'Key', defaultMessage: 'Key' })}
          </div>
          <Input
            className={styles.input}
            value={editRole?.key}
            onChange={(e) =>
              setEditRole({
                ...editRole,
                key: e.target.value,
                name: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {formatMessage({
              id: 'Permissions',
              defaultMessage: 'Permissions',
            })}
          </div>
          <Select
            className={styles.input}
            value={editRole?.permissions}
            multiple
            onChange={(data) =>
              setEditRole({
                ...editRole,
                permissions: data as number[],
              })
            }
            showSearch
          >
            {permissions.map(({ key, id }) => (
              <Select.Option key={key} value={id as number}>
                {key}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.saveButton}
            type="primary"
            onClick={handleSave}
            loading={createLoading || updateLoading}
            disabled={!editRole.key}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Role;
