import { FC, useState } from 'react';
import { Table, Space, message } from 'antd';
import {
  Button,
  Modal,
  Input,
  Select,
  AttachedSelect,
  FreeForm,
  FormItem,
  FormSection,
} from 'iglooform';
import { useIntl, history } from 'umi';
import { useRequest } from '@umijs/hooks';
import { useRecoilValue } from 'recoil';
import { currentPlatformState } from '@/store/global';
import { EditOutlined } from '@ant-design/icons';
import { getPlatforms, createPlatform, updatePlatform } from './service';

import styles from './index.less';

const AdminPlatform: FC = () => {
  const [platforms, setPlatforms] = useState<Auth.Platform[]>();
  const { formatMessage } = useIntl();
  const [form] = FreeForm.useForm();
  const currentPlatform = useRecoilValue(currentPlatformState);
  const [showModal, setShowModal] = useState(false);
  const [editPlatform, setEditPlatform] = useState<Auth.Platform | null>(null);

  const { run: refresh, loading } = useRequest(getPlatforms, {
    onSuccess: ({ platforms }) => {
      setPlatforms(platforms);
    },
  });

  const { run: create, loading: createLoading } = useRequest(createPlatform, {
    manual: true,
    onSuccess: () => {
      refresh();
      setShowModal(false);
      form.resetFields();
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  const { run: update, loading: uploadLoading } = useRequest(updatePlatform, {
    manual: true,
    onSuccess: () => {
      refresh();
      setShowModal(false);
      form.resetFields();
    },
    onError: (err: any) => {
      message.error(err?.data?.message);
    },
  });

  if (currentPlatform !== 'admin') {
    history.push('/platforms');
    return null;
  }

  const openCreateModal = () => {
    setShowModal(true);
  };

  const handleClickEditButton = (record: Auth.Platform) => {
    setEditPlatform(record);

    setShowModal(true);
    form.setFieldsValue({
      ...record,
      countries: record.countries || [],
    });
  };

  const handleSave = async () => {
    await form.validateFields();
    const formValue = form.getFieldsValue();
    if (editPlatform?.id) {
      update({
        ...formValue,
        id: editPlatform.id,
      });
      return;
    }

    create(formValue);
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        <Button type="default" onClick={openCreateModal}>
          {formatMessage({
            id: 'New Platform',
            defaultMessage: 'New Platform',
          })}
        </Button>
      </div>
      <Table
        className={styles.table}
        loading={loading}
        dataSource={platforms}
        columns={[
          {
            title: formatMessage({ id: 'Key', defaultMessage: 'Key' }),
            dataIndex: 'key',filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
              return (
                <Space direction="vertical" size={16} style={{ padding: 24 }}>
                  <Input
                    onChange={(e) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                  />
                  <Space size={16}>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        confirm();
                      }}
                      type="text"
                      size="small"
                    >
                      Reset
                    </Button>
                    <Button onClick={() => confirm()} type="text" size="small">
                      Confirm
                    </Button>
                  </Space>
                </Space>
              );
            },
            onFilter: (value, record) =>
              Boolean(record.key?.includes(value as string)),
          },
          {
            title: formatMessage({
              id: 'Name',
              defaultMessage: 'Name',
            }),
            dataIndex: 'name',filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => {
              return (
                <Space direction="vertical" size={16} style={{ padding: 24 }}>
                  <Input
                    onChange={(e) => setSelectedKeys([e.target.value])}
                    value={selectedKeys[0]}
                  />
                  <Space size={16}>
                    <Button
                      onClick={() => {
                        setSelectedKeys([]);
                        confirm();
                      }}
                      type="text"
                      size="small"
                    >
                      Reset
                    </Button>
                    <Button onClick={() => confirm()} type="text" size="small">
                      Confirm
                    </Button>
                  </Space>
                </Space>
              );
            },
            onFilter: (value, record) =>
              Boolean(record.name?.includes(value as string)),
          },
          {
            title: formatMessage({
              id: 'Type',
              defaultMessage: 'Type',
            }),
            dataIndex: 'kind',
          },
          {
            title: formatMessage({
              id: 'Countries',
              defaultMessage: 'Countries',
            }),
            dataIndex: 'countries',
            render: (value) => value?.join(', '),
          },
          {
            title: formatMessage({
              id: 'Valid',
              defaultMessage: 'Valid',
            }),
            dataIndex: 'valid',
            render:(value)=>String(value)
          },
          {
            title: formatMessage({
              id: 'Operation',
              defaultMessage: 'Operation',
            }),
            width: '20%',
            render: (_, record) =>
              record?.key === 'admin' ? (
                '-'
              ) : (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleClickEditButton(record)}
                  type="link"
                />
              ),
          },
        ]}
        rowKey="key"
      />
      <Modal
        className={styles.modal}
        width={600}
        forceRender={false}
        maskClosable={false}
        visible={showModal}
        footer={null}
        onCancel={() => {
          setEditPlatform(null);
          setShowModal(false);
          form.resetFields();
        }}
        title={formatMessage({
          id: 'New Platform',
          defaultMessage: 'New Platform',
        })}
        destroyOnClose
        limitHeight={false}
      >
        <FreeForm form={form}>
          <FormSection>
            <FormItem label={'Key'} name={'key'} fullRow>
              <Input />
            </FormItem>
            <FormItem label={'Name'} name={'name'} fullRow>
              <Input />
            </FormItem>
            <FormItem label={'Countries'} name={'countries'} fullRow>
              <AttachedSelect
                multiple
                className={styles.input}
                options={[
                  {
                    label: 'Vietnam',
                    value: 'Vietnam',
                  },
                  {
                    label: 'Indonesia',
                    value: 'Indonesia',
                  },
                  {
                    label: 'Malaysia',
                    value: 'Malaysia',
                  },
                  {
                    label: 'Philippines',
                    value: 'Philippines',
                  },
                  {
                    label: 'Singapore',
                    value: 'Singapore',
                  },
                  {
                    label: 'Thailand',
                    value: 'Thailand',
                  },
                ]}
              />
            </FormItem>
            <FormItem
              label={'Valid'}
              name={'valid'}
              options={[
                {
                  label: 'True',
                  value: true,
                },
                {
                  label: 'False',
                  value: false,
                },
              ]}
              fullRow
            >
              <Select></Select>
            </FormItem>
            <FormItem
              label={'Type'}
              name={'kind'}
              fullRow
              initialValue={'admin_v3'}
            >
              <Input />
            </FormItem>
          </FormSection>
        </FreeForm>
        <div className={styles.buttons}>
          <Button
            className={styles.saveButton}
            type="primary"
            onClick={handleSave}
            loading={createLoading || uploadLoading}
          >
            {formatMessage({ id: 'Confirm', defaultMessage: 'Confirm' })}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminPlatform;
